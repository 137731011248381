import { addDays, addMinutes, format } from 'date-fns';

import { FE_DATE_FORMAT, HOURS_TIME } from '@/constants/global';
import { assertNever } from '@/types';

import { ActionTypes, State } from './types';

export const initialState = {
  isStoreLoaded: false,
  booking: {
    car: null,
    startDate: format(addMinutes(addDays(new Date(), 1), 60), FE_DATE_FORMAT),
    startTime: format(addMinutes(addDays(new Date(), 1), 60), HOURS_TIME),
    endDate: format(addMinutes(addDays(new Date(), 2), 60), FE_DATE_FORMAT),
    endTime: format(addMinutes(addDays(new Date(), 2), 60), HOURS_TIME),
    addressToDeliverGeo: null,
    addressToPickupGeo: null,
    carPrice: null,
    carCurrency: null,
    color: null,
    bodyType: null,
    isOrderCreated: false,
    extraServices: {},
  },
};

export const AppReducer = (state: State, action: ActionTypes) => {
  switch (action.type) {
    case 'INITIALIZE_STORE': {
      return {
        ...action.payload,
        isStoreLoaded: true,
      };
    }
    case 'UPDATE_BOOKING': {
      return {
        ...state,
        booking: {
          ...state.booking,
          ...action.payload,
        },
      };
    }
    default:
      assertNever(action);
      return state;
  }
};
