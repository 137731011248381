import { FC, memo, useEffect, useState } from 'react';
import { useBoolean } from 'react-use-boolean';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import cn from 'classnames';

import { Route } from '@/constants/routes';
import { LanguagesSwitch } from '@/features/LanguagesSwitch';
import { LocaleType } from '@/types';
import { useClickOutside } from '@/utils/useClickOutside';
import { useLogin } from '@/utils/useLogin';

const Logo = require('assets/icons/logo.svg');
const UserIcon = require('assets/icons/user.svg');
const DropdownArrowIcon = require('assets/icons/dropdown-arrow-icon.svg');

const Notification = dynamic(() => import('@/features/Notification').then((module) => module.Notification), {
  ssr: false,
});

type Props = {
  isDefaultLogged: boolean;
};

export const Header: FC<Props> = memo(({ isDefaultLogged }) => {
  const { t } = useTranslation();

  const router = useRouter();

  const locale = router.locale as LocaleType;

  const [navbar, { toggle: toggleNavbar, off: closeNavbar }] = useBoolean(false);
  const [logoutButton, setLogout] = useState(false);

  const { isLogged, logout } = useLogin({ isDefaultLogged });

  useEffect(() => {
    closeNavbar();
  }, [router.route]);

  const isBookACarPage = router.pathname.includes('/book-car');

  const { refOutside } = useClickOutside({ onClose: closeNavbar });

  return (
    <>
      <div
        className="hidden lg:grid font-medium fixed grid-cols-2 top-10 left-0 right-0
      bg-white container mx-auto rounded-full shadow-3xl px-12 py-5 z-50 gap-16">
        <div className="flex items-center justify-between">
          <Link prefetch={false} href={Route.Home}>
            <Image src={Logo} alt={t('mainPage.keywords')} className="h-11 w-11" />
          </Link>
          {!isBookACarPage ? (
            <Link
              href={Route.BookingSearchCar}
              // eslint-disable-next-line max-len
              className="py-2 px-7 border-2 primary-color-border primary-color-text transition hover:bg-blue-50 rounded-xl">
              {t('bookACar')}
            </Link>
          ) : null}
        </div>
        <div className="flex items-center justify-between xl:justify-end gap-4 lg:gap-12">
          <div className="flex space-x-6 transition hover:opacity-70">
            {!isLogged && <Link href={Route.Register}>{t('createAnAccount')}</Link>}
          </div>

          <LanguagesSwitch isDefaultLogged={isDefaultLogged} onClick={closeNavbar} />

          {isLogged ? (
            <div className="flex gap-12">
              <Notification locale={locale} isDefaultLogged={isDefaultLogged} />
              <div
                ref={refOutside}
                className="flex items-center gap-3 relative cursor-pointer"
                onClick={() => setLogout(!logoutButton)}>
                <div className="transition hover:opacity-70 flex item-center gap-3">
                  <Image src={UserIcon} alt={t('account.profile')} />
                  <Image className={cn({ 'rotate-180': logoutButton })} src={DropdownArrowIcon} alt={t('moreInfo')} />
                </div>
                {logoutButton ? (
                  <div
                    className="rounded-xl border primary-color-border primary-color-text
                    absolute top-10 -right-5 py-4 px-7 bg-white shadow-3x l">
                    <Link prefetch={false} href={Route.User} className="block mb-4 transition hover:opacity-70">
                      {t('account.profile')}
                    </Link>
                    <button className="transition hover:opacity-70" type="button" onClick={logout}>
                      {t('logout')}
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <Link prefetch={false} href={Route.Login} className="primary-color-text transition hover:opacity-70">
              {t('signIn')}
            </Link>
          )}
        </div>
      </div>

      <div className="block lg:hidden">
        <div className="fixed w-full top-4 z-30">
          <div
            className={`flex mx-6 items-center bg-white rounded-full
            ${navbar ? 'justify-end h-12' : 'justify-between shadow px-6 h-16'}`}>
            <Link prefetch={false} href={Route.Home} className={navbar ? 'hidden' : ''} onClick={closeNavbar}>
              <Image src={Logo} alt={t('logo')} className="h-11 w-11" />
            </Link>
            {!isBookACarPage ? (
              <Link
                href={Route.BookingSearchCar}
                className={cn(
                  // eslint-disable-next-line max-len
                  'py-1 px-4 w-fit border-2 rounded-xl primary-color-border primary-color-text transition hover:bg-blue-50',
                  { hidden: navbar }
                )}
                onClick={closeNavbar}>
                {t('bookACar')}
              </Link>
            ) : null}

            {!navbar ? (
              <div className="space-y-1.5 w-8 cursor-pointer transition hover:opacity-70" onClick={toggleNavbar}>
                <span className="block w-6 h-0.5 primary-color-bg" />
                <span className="block w-6 h-0.5 primary-color-bg" />
                <span className="block w-6 h-0.5 primary-color-bg" />
              </div>
            ) : (
              <div className="w-8 cursor-pointer transition hover:opacity-70">
                <svg
                  onClick={toggleNavbar}
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-8 h-8 primary-color-text"
                  viewBox="0 0 20 20"
                  fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293
                  4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586
                  10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
        <div
          className={cn('fixed', 'inset-0', 'bg-white', 'z-20', 'px-6', 'pt-20', 'space-y-8', {
            block: navbar,
            hidden: !navbar,
          })}>
          <LanguagesSwitch isDefaultLogged={isDefaultLogged} onClick={closeNavbar} />
          <div className="flex flex-col space-y-4 text-lg font-medium">
            {isLogged ? (
              <>
                <Link
                  className="transition hover:opacity-70 block"
                  prefetch={false}
                  onClick={closeNavbar}
                  href={Route.User}>
                  {t('account.profile')}
                </Link>
                <button
                  data-testid="logout-button"
                  type="button"
                  onClick={() => {
                    logout();
                    closeNavbar();
                  }}
                  className="text-left transition hover:opacity-70">
                  {t('logout')}
                </button>
              </>
            ) : (
              <>
                <Link
                  prefetch={false}
                  className="transition hover:opacity-70"
                  onClick={closeNavbar}
                  href={Route.Register}>
                  {t('createAnAccount')}
                </Link>
                <Link
                  prefetch={false}
                  onClick={closeNavbar}
                  href={Route.Login}
                  className="primary-color-text block font-medium transition hover:opacity-70">
                  {t('signIn')}
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
});
