import { FC } from 'react';
import cn from 'classnames';

export type Props = {
  onClick?: VoidFunction;
  title: string;
  className?: string;
  id?: string;
  disabled?: boolean;
  type?: 'button' | 'submit';
  variant?: 'default' | 'outline' | 'negative';
  isPending?: boolean;
};

export const Button: FC<Props> = ({
  onClick,
  variant = 'default',
  title,
  className,
  id,
  disabled,
  type = 'button',
  isPending = false,
  ...rest
}) => (
  <button
    id={id}
    disabled={disabled}
    type={type}
    className={cn(
      'text-white',
      'flex',
      'flex-row',
      'py-3',
      'px-12',
      'block',
      'w-fit',
      'rounded-xl',
      'text-lg',
      'font-bold',
      'disabled:opacity-25',
      'hover:-translate-y-1',
      'transition',
      {
        'primary-color-bg': variant === 'default',
        '!bg-transparent': variant === 'outline',
        'negative-color-bg': variant === 'negative',
        'justify-between': isPending,
        'justify-center': !isPending,
      },
      className
    )}
    onClick={onClick}
    {...rest}>
    {title}
    {isPending && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6 animate-spin mt-0.5 ml-1">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          // eslint-disable-next-line max-len
          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
        />
      </svg>
    )}
  </button>
);
